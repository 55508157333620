import {useEffect, useState} from 'react';
import type {NextRouter} from 'next/router';

export function useRouter(): NextRouter | null {
	const [router, setRouter] = useState<NextRouter | null>(null);

	useEffect(() => {
		async function getRouter() {
			try {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore - Vite specific
				const isVite = import.meta.env?.BASE_URL != null;
				if (!isVite) {
					const nextRouter = await import('next/router').then(
						(mod) => mod.default,
					);
					if (nextRouter != null) {
						setRouter(nextRouter);
					}
				}
			} catch {
				// do nothing
			}
		}

		getRouter();
	}, [setRouter]);

	return router;
}
