import {
	type ReactNode,
	type AnchorHTMLAttributes,
	type ForwardedRef,
	forwardRef,
	lazy,
} from 'react';
import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {useRouter} from '../use-router';
import style from './Link.module.css';
import type {LinkProps} from 'next/link';
import type {UrlObject} from 'url';

export interface Props {
	color?: 'primary' | 'destructive';
	href?: string | UrlObject;
	as?: LinkProps['as'];
	nav?: boolean;
	activeClassName?: string;
	className?: string;
	children?: ReactNode;
	target?: AnchorHTMLAttributes<HTMLLinkElement>['target'];
	replace?: boolean;
	onClick?: (...any: unknown[]) => void;
	disabled?: boolean;
}

export const Link = lazy(async () => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore - Vite specific
	const isVite = import.meta.env?.BASE_URL != null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let NextLink: any;
	try {
		if (!isVite) {
			NextLink = await import('next/link').then((mod) => mod.default);
		}
	} catch {
		// do nothing
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let TanstackLink: any;
	try {
		if (isVite) {
			TanstackLink = await import('@tanstack/react-router').then(
				(mod) => mod.createLink,
			);
		}
	} catch {
		// do nothing
	}

	const Link = (props: Props, ref: ForwardedRef<HTMLAnchorElement>) => {
		const router = useRouter();
		const href = props.href;

		const isExternalHref =
			!href || (typeof href === 'string' && /^https?:\/\//.test(href));
		if (isExternalHref) {
			return <RefLink {...props} ref={ref} />;
		}

		if (NextLink) {
			const active =
				(typeof props.href === 'string' && router?.asPath === props.href) ||
				(typeof props.href === 'object' &&
					router?.asPath === props.href.pathname);

			return (
				<NextLink
					href={props.href}
					as={props.as}
					replace={props.replace}
					legacyBehavior
				>
					<RefLink {...props} active={active} ref={ref} />
				</NextLink>
			);
		}
		if (TanstackLink) {
			const CreatedLinkComponent = TanstackLink(RefLink);
			return <CreatedLinkComponent preload="intent" {...props} ref={ref} />;
		}

		return <RefLink {...props} ref={ref} />;
	};

	return {
		default: forwardRef(Link),
	};
});

const BaseLink = (
	{
		color,
		href,
		as,
		nav,
		activeClassName,
		className,
		replace,
		disabled,
		active,
		...props
	}: Props & {active?: boolean},
	ref: ForwardedRef<HTMLAnchorElement>,
) => {
	const activeClass = nav ? style.Active : activeClassName;

	const classes = classNames(
		CSSVariants(style, 'LinkColor', color),
		className,
		active && activeClass,
		disabled && style.Disabled,
	);

	const path = typeof href === 'object' ? href.toString() : href;

	return (
		<a
			role="link"
			href={path ?? undefined}
			className={classes}
			ref={ref}
			{...props}
		/>
	);
};

const RefLink = forwardRef(BaseLink);
