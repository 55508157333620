import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {Icon} from '../../../Icon';
import {Stack} from '../../../Stack';
import {Paragraph} from '../../../Typography';
import {Link} from '../../../Link';
import {useToast} from '../../../Toast';
import style from './NavLink.module.css';
import type {IconColor} from '../../../Icon/Icon';
import type {IconProp} from '@fortawesome/fontawesome-svg-core';
import type {ReactNode} from 'react';

export type IconSize = 'sm' | 'md' | 'lg';

interface Props {
	text: string;
	path: string;
	icon?: IconProp;
	circleIcon?: boolean;
	iconColor?: IconColor;
	iconSize?: IconSize;
	textDecoration?: ReactNode;
	textDecorationRight?: boolean;
	target?: string;
	onClick?: () => void;
	disabled?: boolean;
	disabledMessage?: string;
	alignment?: 'row' | 'column';
}

export function NavLink({
	text,
	path,
	onClick,
	icon,
	iconColor = 'white',
	iconSize = 'md',
	textDecoration,
	target,
	disabled,
	disabledMessage,
	alignment = 'row',
}: Props) {
	const addToast = useToast();
	const iconMarkup = icon ? (
		<Icon
			icon={icon}
			color={iconColor}
			className={classNames(
				CSSVariants(
					style,
					'IconSize',
					alignment === 'column' ? 'lg' : iconSize,
				),
				disabled && style.IconDisabled,
			)}
		/>
	) : null;

	if (disabled) {
		return (
			<div
				className={classNames(
					style.NavLinkDisabled,
					alignment === 'column' && style.NavLinkColumn,
				)}
				onClick={() => {
					if (disabledMessage) {
						addToast({type: 'info', message: disabledMessage});
					}
				}}
			>
				<Stack
					direction="row"
					spacing="xs"
					className={style.NavLinkTextContainer}
					justifyContent={alignment === 'column' ? 'center' : 'flex-start'}
				>
					<Stack direction={alignment} justifyContent="flex-start" spacing="xs">
						{iconMarkup}
						<Paragraph className={style.NavLinkDisabledText}>{text}</Paragraph>
						{textDecoration}
					</Stack>
				</Stack>
			</div>
		);
	}

	return (
		<Link
			target={target}
			href={path}
			onClick={() => {
				onClick?.();
			}}
			className={alignment === 'row' ? style.NavLinkRow : style.NavLinkColumn}
			activeClassName={classNames(
				style.NavLinkActive,
				alignment === 'column' && style.NavLinkActiveColumn,
			)}
		>
			<Stack
				direction="row"
				spacing="xs"
				className={style.NavLinkTextContainer}
				justifyContent={alignment === 'column' ? 'center' : 'flex-start'}
			>
				<Stack direction={alignment} justifyContent="flex-start" spacing="xs">
					{iconMarkup}
					<Paragraph className={style.NavLinkText}>{text}</Paragraph>
					{textDecoration}
				</Stack>
			</Stack>
		</Link>
	);
}
