import {Suspense, type ReactNode} from 'react';
import {
	GraphQLClientError as ClientError,
	ApiClientProvider,
	SocketProvider,
} from '@halp/api';
import {ToastProvider} from '@halp/ui';
import {ToastContext} from '@halp/ui/src/Toast/ToastProvider';
import {UserContext, UserProvider} from '../../Users';
import {NotificationProvider} from '../../Notifications/NotificationProvider';
import type {SessionUser} from '../../Users';

interface Props {
	children: ReactNode;
	logoutMessage: string;
	onUserLoaded?: (user: SessionUser) => void;
}

export function AppProvider({children, ...props}: Props) {
	return (
		<Suspense>
			<ToastProvider>
				<ToastContext.Consumer>
					{(addToast) => (
						<ApiClientProvider
							onError={(error) => {
								if (
									error instanceof ClientError &&
									error.response.statuscode === 504
								) {
									addToast({message: error.message, type: 'error'});
								}
							}}
						>
							<UserProvider {...props}>
								<UserContext.Consumer>
									{(value) => (
										<SocketProvider
											url="/user-socket"
											options={{params: {token: value?.user?.token || null}}}
										>
											<NotificationProvider>{children}</NotificationProvider>
										</SocketProvider>
									)}
								</UserContext.Consumer>
							</UserProvider>
						</ApiClientProvider>
					)}
				</ToastContext.Consumer>
			</ToastProvider>
		</Suspense>
	);
}
