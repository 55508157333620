// duplicated from @halp/foundation/env to avoid cycles

import {isBrowser} from '@halp/util';

export const HOST =
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore - Vite specific
	import.meta.env?.DEV || process?.env?.NODE_ENV === 'development'
		? 'halp.localhost:4000'
		: 'halp.co';

const PROTOCOL = isBrowser() ? window.location.protocol : 'http:';

export const API_DOMAIN = `${PROTOCOL}//api.${HOST}`;
export const GRAPHQL_DOMAIN = `${PROTOCOL}//graphql.${HOST}`;
