import {useEffect, useRef, useState} from 'react';
import {
	faAngleLeft,
	faAngleRight,
	faCog,
} from '@fortawesome/free-solid-svg-icons';
import {Avatar, Link, Stack, Paragraph, Icon, Heading} from '@halp/ui';
import {useI18n} from '../../i18n';
import {useUser} from '../UserProvider';
import {Paths} from '../../Paths';
import style from './AdminUserMenu.module.css';
import {SignOut} from './SignOut';

export function AdminUserMenu() {
	const {t} = useI18n();
	const {user} = useUser();
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			if (
				ref.current &&
				event.target &&
				!ref.current.contains(event.target as Node)
			) {
				setOpen(false);
			}
		};

		window.addEventListener('mousedown', handleClick);

		return () => {
			window.removeEventListener('mousedown', handleClick);
		};
	}, [ref]);

	if (!user) return null;

	const caret = open ? faAngleLeft : faAngleRight;

	const content = open ? (
		<div className={style.DropdownContent}>
			<Stack
				direction="column"
				alignItems="flex-start"
				spacing="xs"
				className={style.DropdownContentItems}
			>
				<Link nav={true} href={Paths.account.profile.url()}>
					<Stack direction="row" justifyContent="flex-start" spacing="xs">
						<Icon icon={faCog} color="white" />
						<Heading type="h6" color="white">
							{t('navigation.account')}
						</Heading>
					</Stack>
				</Link>
				<SignOut button={false} />
			</Stack>
		</div>
	) : null;

	return (
		<div className={style.UserMenu}>
			<div className={style.DropdownWrapper} ref={ref}>
				<div onClick={() => setOpen(!open)}>
					<Stack direction="row" justifyContent="space-between">
						<Stack
							direction="row"
							justifyContent="flex-start"
							spacing="xs"
							fullWidth
						>
							<Avatar text={user.name} size="small" />
							<Stack
								direction="column"
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<Paragraph color="white">{user.name}</Paragraph>
								<Paragraph wordBreak="break-word" color="white">
									{user.email}
								</Paragraph>
							</Stack>
						</Stack>
						<Icon icon={caret} color="white" />
					</Stack>
				</div>
				{content}
			</div>
		</div>
	);
}
