import {faArrowRightToBracket} from '@fortawesome/free-solid-svg-icons';
import {useRouter} from 'next/router';
import {Button, Heading, Icon, Link, Stack} from '@halp/ui';
import {useI18n} from '../../i18n';
import {SESSION_DOMAIN} from '../../env';
import {useSession} from '../UserProvider';
import {Paths} from '../../Paths';
import style from './SignOut.module.css';

interface Props {
	button?: boolean;
}

export function SignOut({button = true}: Props) {
	const {t} = useI18n();
	const {logout} = useSession();
	const router = useRouter();

	async function onSubmit() {
		const response = await fetch(SESSION_DOMAIN, {method: 'DELETE'});
		if (response.ok) {
			logout();
			if (process?.env.NODE_ENV === 'development') {
				router.push(Paths.dashboard.url());
			} else {
				router.push(Paths.marketing.home);
			}
		}
	}

	return button ? (
		<Button onClick={onSubmit} thin>
			{t('sign_out')}
		</Button>
	) : (
		<Link onClick={onSubmit}>
			<Stack direction="row" justifyContent="flex-start" spacing="xs">
				<Icon icon={faArrowRightToBracket} color="white" />
				<button type="submit" className={style.SignOutLink}>
					<Heading type="h6" color="white">
						{t('sign_out')}
					</Heading>
				</button>
			</Stack>
		</Link>
	);
}
