/* eslint-disable import-x/no-named-as-default-member */
import dayjsBase from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjsBase.extend(utc);
dayjsBase.extend(advancedFormat);
dayjsBase.extend(timezone);
dayjsBase.extend(relativeTime);

export const dayjs = dayjsBase;
