import classNames from 'classnames';
import {useEffect, useRef} from 'react';
import style from './IFrameRenderer.module.css';

interface Props {
	src: string;
	allowFullScreen?: boolean;
	className?: string;
	fullSize?: boolean;
}

export function IFrameRenderer({
	src,
	className,
	fullSize = true,
	allowFullScreen,
}: Props) {
	const ref = useRef<HTMLIFrameElement | null>(null);
	const classes = classNames(fullSize && style.FullSize, className);

	useEffect(() => {
		const frame = ref.current;
		if (frame != null && fullSize) {
			let timer: NodeJS.Timeout;
			const cb = () => {
				timer = setInterval(() => {
					if (frame) {
						frame.style.height =
							frame.contentDocument?.body.scrollHeight + 'px';
					}
				}, 500);
			};

			frame.addEventListener('load', cb);

			return () => {
				clearInterval(timer);
				frame?.removeEventListener('load', cb);
			};
		}

		return () => null;
	});

	return (
		<iframe
			ref={ref}
			className={classes}
			src={src}
			allowFullScreen={allowFullScreen}
		/>
	);
}
