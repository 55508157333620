import {useRouter} from 'next/router';
import {isBrowser} from '@halp/util';
import {Paths} from '../../Paths';
import {useSession, useUser} from '../UserProvider';
import {useUserType} from '../Permission';
import type {Role} from '../Permission';
import type {NextComponentType, NextPageContext} from 'next';

interface Options {
	from?: string;
	to?: string;
	redirectToLogin?: boolean;
	role?: Role | Role[];
}

export function withUserProtectedRoute<Props>(
	WrappedComponent: NextComponentType<NextPageContext, unknown, Props>,
	{to, from, redirectToLogin = true, role}: Options = {},
) {
	const UserProtectedRouteWrapper = (props: Props) => {
		const router = useRouter();
		const {hasRenewToken} = useSession();
		const {user} = useUser();
		const userType = useUserType();
		const allowed =
			role == null ||
			user?.admin ||
			role === userType ||
			(userType && role.includes(userType));

		if (isBrowser()) {
			if (!hasRenewToken && redirectToLogin) {
				const location = router.asPath !== '/' ? window.location.href : null;
				const path = from ?? location;

				// todo: @jtsmills
				if (router.asPath !== Paths.login.index.url()) {
					router.replace({
						pathname: Paths.login.index.url(),
						query: path ? {returnUrl: path} : null,
					});
				}

				return null;
			}

			if (!hasRenewToken || (user != null && !allowed)) {
				router.replace(to ?? Paths.lost.url(userType));
				return null;
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return <WrappedComponent {...(props as any)} />;
	};

	return UserProtectedRouteWrapper;
}
