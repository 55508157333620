import {Domains} from './domains';
import {Path} from './path';
import type {Role} from '../Users/Permission';

export const PROGRAM_PATHS = {
	programs: {
		index: new Path((type?: Role) => {
			if (type === 'admin') {
				return new Path(`/programs/programs`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/programs/programs`, Domains.coach).url();
			}
			return new Path('/').url();
		}),
		show: new Path((id: number | string, type?: Role) => {
			if (type === 'admin') {
				return new Path(`/programs/show/${id}`, Domains.admin).url();
			} else if (type === 'coach') {
				return new Path(`/programs/show/${id}`, Domains.coach).url();
			}
			return new Path('/').url();
		}),
	},
	schools: new Path((type?: Role) => {
		if (type === 'admin') {
			return new Path(`/programs/school`, Domains.admin).url();
		} else if (type === 'coach') {
			return new Path(`/programs/school`, Domains.coach).url();
		}
		return new Path('/').url();
	}),
	subjects: new Path((type?: Role) => {
		if (type === 'admin') {
			return new Path(`/programs/subject`, Domains.admin).url();
		} else if (type === 'coach') {
			return new Path(`/programs/subject`, Domains.coach).url();
		}
		return new Path('/').url();
	}),
	majors: new Path((type?: Role) => {
		if (type === 'admin') {
			return new Path(`/programs/major`, Domains.admin).url();
		} else if (type === 'coach') {
			return new Path(`/programs/major`, Domains.coach).url();
		}
		return new Path('/').url();
	}),
};
