import NextHead from 'next/head';

interface Props {
	title?: string;
	metaDescription?: string;
}

export function Head({
	title = 'University Coaching, Admissions Tips, and Student Mentorship',
	metaDescription = 'Online programs that give you 1-on-1 coaching, hands on mentorship, and admissions support so you get into your target schools',
}: Props) {
	return (
		<NextHead>
			<title>{`Halp | ${title}`}</title>
			<meta property="og:title" content={title} key="title" />
			<meta
				name="description"
				content={metaDescription}
				key="metaDescription"
			/>
		</NextHead>
	);
}
