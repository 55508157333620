import classNames from 'classnames';
import {lazy} from 'react';
import style from './Image.module.css';
// TODO: @jtsmills not this
import type {ImageProps, StaticImageData} from 'next/image';

export interface Props extends Omit<ImageProps, 'width' | 'height' | 'src'> {
	objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
	src: StaticImageData | string | undefined;
}

export const Image = lazy(async () => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore - Vite specific
	const isVite = import.meta.env?.BASE_URL != null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let NextImage: any;
	try {
		if (!isVite) {
			NextImage = await import('next/image').then((mod) => mod.default);
		}
	} catch {
		// do nothing
	}

	return {
		default: function ({className, ...props}: Props) {
			const classes = classNames(style.Image, className);

			if (NextImage) {
				return (
					<div className={classNames(style.ImageContainer, className)}>
						<NextImage fill {...props} className={classes} />
					</div>
				);
			}

			if (typeof props.src === 'string') {
				return (
					<div className={classNames(style.ImageContainer, className)}>
						<img {...props} className={classes} src={props.src} />
					</div>
				);
			}

			return null;
		},
	};
});
