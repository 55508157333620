import {isBrowser} from '@halp/util';
import {useBreakpoint} from './useBreakpoint';
import type {ScreenSize} from './BreakpointUtil';
import type {ReactNode} from 'react';

interface SizeProps {
	children: ReactNode;
	size: ScreenSize;
	max?: never;
	min?: never;
}

interface MinMaxProps {
	children: ReactNode;
	min?: ScreenSize | number;
	max?: ScreenSize | number;
	size?: never;
}

type Props = SizeProps | MinMaxProps;

export function Breakpoint(props: Props) {
	const {min, max} = props as MinMaxProps;
	const {size} = props as SizeProps;

	const shouldRender = useBreakpoint(
		size ? {size: size} : {min: min, max: max},
	);

	return isBrowser() && shouldRender ? <>{props.children}</> : null;
}
