import {useUser} from '../UserProvider';

export type Role = 'admin' | 'coach' | 'student' | 'ambassador' | 'partner';

export function useUserType(): Role | undefined {
	const {user} = useUser();

	if (user?.admin) {
		return 'admin';
	} else if (user?.coachProfile !== null) {
		return 'coach';
	} else if (user?.ambassadorProfile !== null) {
		return 'ambassador';
	} else if (user?.partnerProfile !== null) {
		return 'partner';
	} else if (user?.studentProfile !== null) {
		return 'student';
	}
	return undefined;
}
