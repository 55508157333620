import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import style from './Icon.module.css';
import type {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';

const COLOR_MAP: Record<IconColor, string> = {
	primary: 'var(--icon-color-primary)',
	grey: 'var(--icon-color-grey)',
	white: 'var(--icon-color-white)',
	orange: 'var(--icon-color-orange)',
	yellow: 'var(--icon-color-yellow)',
	black: 'var(--icon-color-black)',
	green: 'var(--icon-color-green)',
	blue: 'var(--icon-color-blue)',
	purple: 'var(--icon-color-purple)',
	red: 'var(--icon-color-red)',
	pink: 'var(--icon-color-pink)',
};

export type IconColor =
	| 'grey'
	| 'primary'
	| 'white'
	| 'orange'
	| 'yellow'
	| 'black'
	| 'green'
	| 'blue'
	| 'purple'
	| 'red'
	| 'pink';

export interface Props {
	icon: IconProp;
	color?: IconColor;
	size?: SizeProp;
	hover?: boolean;
	spin?: boolean;
	onClick?: () => void;
	className?: string;
}

export type IconDefinition = IconProp;

export function Icon({
	icon,
	color,
	size,
	hover,
	spin = false,
	onClick,
	className,
}: Props) {
	const iconColor = color ? COLOR_MAP[color] : undefined;
	const iconClass = classNames(hover && style.Icon, className);
	return (
		<FontAwesomeIcon
			icon={icon}
			size={size}
			color={iconColor}
			onClick={onClick}
			spin={spin}
			className={iconClass}
		/>
	);
}
